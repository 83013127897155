<template>
    <default-template>
        <!--Main Section [S]-->
        <div class="main-contents">

            <slide-banner v-if="!$vuetify.breakpoint.mobile" class="main-pc" code="main-pc" hide-pagination />
            <slide-banner v-else class="main-mo" code="main-mo" hide-pagination />

            <!--기존 구조 [S]-->
            <v-responsive slot="gshop-section" width="100%" min-height="100%" class="overflow-visible">

                <!-- <slide-banner v-if="!$vuetify.breakpoint.mobile" code="main-pc" /> -->
                

                <v-responsive width="100%" max-width="1230" class="main-section main-section--product  mx-auto" style="overflow:visible;">

                    <!-- <main-giftcard>
                        <span slot="header" class="font-weight-bold main-category--title">모바일 상품권</span>
                    </main-giftcard> -->

                    <main-giftcard :filter="{ 'category-code': 'book' }">
                        <span slot="header" class="font-weight-bold main-category--title">도서상품권</span>
                    </main-giftcard>

                    <main-giftcard :filter="{ 'category-code': 'cultureland' }" class="mt-8 mt-lg-16">
                        <span slot="header" class="font-weight-bold main-category--title">컬쳐랜드</span>
                    </main-giftcard>

                    <main-giftcard :filter="{ 'category-code': 'afreecatv' }" class="mt-8 mt-lg-16">
                        <span slot="header" class="font-weight-bold main-category--title">아프리카TV 별풍선</span>
                    </main-giftcard>

                    <main-giftcard :filter="{ 'category-code': 'google-giftcard' }" class="mt-8 mt-lg-16">
                        <span slot="header" class="font-weight-bold main-category--title">구글 기프트카드</span>
                    </main-giftcard>

                    <!-- <main-giftcard :filter="{ 'category-code': 'food' }" class="mt-8">
                        <span slot="header" class="headline font-weight-bold">외식·커피·케익 쿠폰</span>
                    </main-giftcard> -->

                    <!-- <main-giftcard :filter="{ 'category-code': 'life' }" class="mt-8">
                        <span slot="header" class="headline font-weight-bold">주유·편의점·생활 쿠폰</span>
                    </main-giftcard> -->

                </v-responsive>
            </v-responsive>
            <!--기존 구조 [E]-->

            <!--Section Board [S]-->
            <!-- <div slot="board-section" class="main-section main-section--board">
                <div class="section-board-wrap" style="max-width:1230px; width:100%; padding:0px 15px; margin:0 auto;">
                    <v-row justify="center">
                        <v-col cols="12" md="6">
                            <main-question-list />
                        </v-col>
                        <v-col cols="12" md="6">
                            <main-faq-list />
                            <main-notification-list class="mt-7" />
                        </v-col>
                    </v-row>
                </div>
            </div> -->
            <!--Section Board [E]-->
        </div>
        <!--Main Section [E]-->
    </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
import GiftcardView from "@/components/client/gshop/giftcard/giftcard-view.vue";
import SlideBanner from "@/components/client/banner/slide-banner.vue";
import MainCategoryList from "@/components/client/main/main-category-list.vue";
import MainCategoryItem from "@/components/client/main/main-category-item.vue";
import MainGiftcard from "@/components/client/main/main-giftcard-list.vue";
import MainQuestionList from "@/components/client/main/main-question-list.vue";
import MainFaqList from "@/components/client/main/main-faq-list.vue";
import MainNotificationList from "@/components/client/main/main-notification-list.vue";

export default {
    components: {
        DefaultTemplate,
        SlideBanner,
        GiftcardView,
        MainCategoryList,
        MainCategoryItem,
        MainGiftcard,
        MainQuestionList,
        MainFaqList,
        MainNotificationList,
    }
}
</script>